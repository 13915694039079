body, html {
    min-height: 100%;
    }
    .bg {
    background-image: url("../../images/oldbridge.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    opacity: 0.5;
    }
